.top-nav-container {    
    align-items: center;
    position: relative;
    z-index: 2;
}

.bredcrumb-text {
    color: #595959 !important;
    font-family: "Rubik", sans-serif !important;
    font-size: 14px !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: -0.6px !important;
}

.notification-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
.notification-container .MuiAvatar-root {
    border-radius: 16px !important;
}

.text-color {
    color: #6200EA !important
}

.navigation-menu {
    display: flex;
    width: 186px;
    max-width: 220px;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--base-blue-grey-2, #ECEDF0);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
.side-arrow-img{
    width: 18px;
    height: 18px;
    padding: 4.333px 0px;
}
.display-flex{
    display: flex;
    align-items: center;
}
.notification-wrap i{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;    
    position: relative;
}
.notification-wrap i img{
    max-width: 100%;
    margin: 0 auto;
}
.notification-wrap i .dot{
    height: 10px;
    width: 10px;
    border: 1px solid #fff;
    background-color: #00E676;
    border-radius: 10px;
    position: absolute;
    bottom: 5px;
    right: 7px;
}