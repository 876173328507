.nav-container {
    padding: 24px;
    background: #FFFFFF;
    height: 100vh;
    overflow: auto;
    position: fixed;
    width: 224px;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.logo-wrapper{
    margin: 0 0 49px;
}
.logo-wrapper img{
    max-width: 100%;
}

.nav-item {
    display: flex !important;
    align-items: center !important;        
    font-family: "Rubik", sans-serif !important;
    margin:0 0 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;
    color: #3C3E49 !important;    
    width: 100%;
    letter-spacing: 0.2px;
}
.nav-item label{
    cursor: pointer;
}
.nav-item img:not(.arrow-img){
    height: 32px;
    width: 32px;
    display: inline-block;
    margin: 0 8px 0 0;
    object-fit: contain;
    object-position: center;
}

.nav-item-active {   
    color: #6200EA !important;
}

.nav-accordion-title .MuiAccordionSummary-content{
    align-items: center;
    padding: 0;
}

.Mui-expanded .nav-accordion-title{
    color: #6200EA !important;
}
.nav-accordion .accordion-nav-list{
    padding-left: 40px !important;
    width: 100%;
}
.nav-accordion .MuiCollapse-wrapper{
    padding-top: 8px !important;
}
.nav-accordion .accordion-nav-list ul{
    width: 100%;
}
.nav-accordion .accordion-nav-list li{
    padding: 6px 4px 6px 8px;
    margin: 0 0 4px;    
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;    
    color: #3C3E49;
    border-radius: 4px;
    cursor: pointer;
}
.nav-accordion .accordion-nav-list li.active-item{
    background-color: #F3EBFF;
}
.help-link-wrap{
    margin-top: 10px;
}
.help-link-wrap .help-link{    
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    margin: 0;
    display: flex;
    align-items: center;
    color: #262626;
    cursor: pointer;
}
.help-link-wrap .help-link img{
    height: 32px;
    width: 32px;
    object-fit: contain;
    object-position: center;
    margin-right: 8px;
}